






















































































import Vue from 'vue';

import RCP from '@/api/RCP';

import Pagination from '@/components/Pagination.vue';
import SelectField from '@/components/SelectField.vue';
import Field from '@/components/Field.vue';
import Partner from '@/components/Partner.vue';
import { ErrorResponse } from '@/types/Errors';

export default Vue.extend({
    name: 'Partners',
    data() {
        return {
            partners: [] as any,
            loading: false,
            error: false,
            searchAllow: false,
            search: '',
            delayTimer: 0,
            pagination: {
                limit: 10,
                currentPage: 1,
                totalItems: 0
            },
            filter: {
                sort: 'desc',
                partner: '0'
            },
            filters: {
                sort: {
                    desc: 'Сначала последние',
                    asc: 'Сначала первые'
                },
                partner: {
                    0: 'Все проекты'
                }
            },
            allPartners: [] as any,
            activePage: true
        };
    },
    mounted() {
        this.getFilters().then(this.getPartners);
    },
    watch: {
        '$route'() {
            this.checkPage();
        },
        'search'(value) {
            this.searchDelay(value);
        },
        'partners'() {
            this.pagination.totalItems = this.partners.length;
        },
        'filter.sort'(val) {
            if (val === 'desc') {
                this.partners = this.partners.sort((a: any, b: any) => b.id - a.id);
            } else {
                this.partners = this.partners.sort((a: any, b: any) => a.id - b.id);
            }
        },
        'filter.partner'() {
            this.getPartners();
        }
    },
    methods: {
        getFilters() {
            return new Promise((resolve) => {
                this.$store.state.filters.projects.forEach((item: any) => {
                    this.filters.partner[+item.id] = item.title;
                });
                resolve();
            });
        },
        getPartners() {
            this.loading = false;
            this.partners = [];
            this.searchAllow = false;
            this.error = false;

            this.pagination.currentPage = 1;
            this.pagination.totalItems = 0;

            const projects = [] as number[];

            if (+this.filter.partner === 0) {
                Object.keys(this.filters.partner).slice(1).forEach((key: string) => {
                    projects.push(+key);
                });
            } else {
                projects.push(+this.filter.partner);
            }

            RCP({
                method: 'Partner.list',
                params: {
                    projects
                }
            }).then((response: any) => {
                this.loading = true;
                if (this.filter.sort === 'desc') {
                    this.partners = response.sort((a, b) => b.id - a.id);
                } else {
                    this.partners = response.sort((a, b) => a.id - b.id);
                }

                const length = this.partners.length;

                if (length || +this.filter.partner !== 0) {
                    this.checkPage();
                    this.searchAllow = true;
                }
            }).catch(() => {
                this.error = true;
                this.loading = true;
            });
        },
        checkPage() {
            const page = this.$route.params.page || 1;

            if (page) {

                if (+page > Math.ceil(this.pagination.totalItems / this.pagination.limit) && this.activePage) {
                    return this.$route.name === 'partners' || this.$router.push('/partners');
                }

                this.pagination.currentPage = +page;
            }
        },
        searchDelay(value: any, time = 500) {
            clearTimeout(this.delayTimer);

            this.delayTimer = setTimeout(() => {
                if (this.$route.name !== 'partners' && this.activePage) {
                    this.$router.push(`/partners`);
                }
                this.searchFilter(value);
            }, time);
        },
        searchFilter(value: string) {
            if (!value) {
                return this.partners = [...this.allPartners];
            }

            if (this.allPartners.length === 0) {
                this.allPartners = [...this.partners];
            }

            let isNaNFlag = false;

            // @ts-ignore
            if (!isNaN(value)) {
                isNaNFlag = true;
            }

            this.partners = this.allPartners.filter((partner: any) => {
                if (isNaNFlag) {
                    if (partner.id === +value) {
                        return true;
                    }
                }

                return partner.title.indexOf(value) + 1 ||
                    (partner.description && partner.description.indexOf(value) + 1) ||
                    (partner.code && String(partner.code).indexOf(value) + 1) ||
                    (partner.additional_code && String(partner.additional_code).indexOf(value) + 1);
            });
        }
    },
    beforeDestroy() {
        this.activePage = false;
    },
    components: {
        Pagination,
        SelectField,
        Field,
        Partner
    }
});
