













































































import Vue from 'vue';

import RCP from '@/api/RCP';

import swal from 'sweetalert2';

import channel from '@/helpers/const/channel';

import { ErrorResponse } from '@/types/Errors';

export default Vue.extend({
    name: 'Partner',
    props: {
        partner: Object
    },
    data() {
        return {
            request: false,
            channel
        };
    },
    methods: {
        deletePartner() {
            if (this.request) {
                return;
            }

            swal.fire({
                title: 'Вы уверены?',
                text: 'Данное действие невозможно будет отменить',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#ff6161',
                cancelButtonText: 'Отмена',
                confirmButtonText: 'Удалить'
            }).then((answer: any) => {
                if (answer.value) {
                    this.request = true;

                    RCP({
                        method: 'Partner.remove',
                        params: {
                            project_id: this.partner.project_id,
                            partner_id: this.partner.id
                        }
                    }).then((response: any) => {
                        this.$emit('refresh');
                    }).catch((error: ErrorResponse) => {
                        if (error.show) {
                            swal.fire({
                                icon: 'error',
                                title: 'Ошибка',
                                text: error.message,
                                confirmButtonText: 'Ок',
                                confirmButtonColor: '#605ab9'
                            });
                        }

                        this.request = false;
                    });
                }
            });
        },
        changeArchive() {
            if (this.request) {
                return;
            }

            this.request = true;

            RCP({
                method: 'Partner.edit',
                params: {
                    project_id: this.partner.project_id,
                    id: this.partner.id,
                    archive: !this.partner.archive
                }
            }).then((response: any) => {
                this.partner.archive = !this.partner.archive;
                this.request = false;
            }).catch((error: ErrorResponse) => {
                if (error.show) {
                    swal.fire({
                        icon: 'error',
                        title: 'Ошибка',
                        text: error.message,
                        confirmButtonText: 'Ок',
                        confirmButtonColor: '#605ab9'
                    });
                }

                this.request = false;
            });
        }
    }
});
